.container-toggle {
    text-align: center;
}
.toggle-switch-div {
    position: relative;
    width: 40px;
    display: inline-block;
    text-align: left;
    top: 23px;
}
.checkbox-toggle {
    display: none;
}
.label-toggle {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0 solid #bbb;
    border-radius: 20px;
}
.inner-toggle {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.2s ease-in 0s;
}
.inner-toggle:before, .inner-toggle:after {
    float: left;
    width: 50%;
    height: 20px;
    padding: 0;
    line-height: 20px;
    color: #fff;
    font-weight: bold;
    box-sizing: border-box;
}
.inner-toggle:before {
    content: "";
    padding-left: 26px;
    background-color: #5ca9e0;
    color: #fff;
}
.inner-toggle:after {
    content: "";
    padding-right: 10px;
    background-color: #2974ab; /* #060; */
    color: #fff;
    text-align: right;
}
.toggle-switch-span {
    display: block;
    width: 10px;
    margin: 5px;
    background: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 18px;
    border: 0 solid #bbb;
    border-radius: 20px;
    transition: all 0.2s ease-in 0s;
}
.checkbox-toggle:checked + .label-toggle .inner-toggle {
    margin-left: 0;
}
.checkbox-toggle:checked + .label-toggle .toggle-switch-span {
    right: 0px;
}
    